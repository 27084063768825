<script setup lang="ts">
/*
  ideas:

  https://javascript.plainenglish.io/crank-up-auto-import-for-dynamic-nuxt-js-components-54e7f198fc16
*/
import { computed, watch } from "vue";
import { useMainStore } from "~/stores/main";

const props = defineProps<{ pageData: Record<string, any> }>();
const config = useRuntimeConfig();
const route = useRoute();
const mainStore = useMainStore();

// -----------------------
// Computed structured content (Runs during SSR)
// -----------------------
const structuredContentRef = computed(() => {
  const pageContent = props.pageData;

  if (!pageContent?.content) return null;

  // Handle preview version
  if ("preview" in route.query && typeof route.query.preview === "string") {
    return (
      pageContent.content.versions?.[route.query.preview]?.data?.pages?.[0]
        ?.frames?.[0]?.component?.components || null
    );
  }

  // Handle published version
  return (
    pageContent.content.published_version?.data?.pages?.[0]?.frames?.[0]
      ?.component?.components || null
  );
});

// -----------------------
// Head Metadata Setup
// -----------------------
const getStyles = computed(() => {
  const content = props.pageData?.content;
  if (!content?.published_version?.data?.styles) return null;
  return content.published_version.data.styles;
});

const setHeadData = () => {
  useHead({
    htmlAttrs: {
      lang: config.public.meta.lang,
    },
    title: props.pageData?.meta?.title || props.pageData?.name,
    meta: [
      props.pageData?.meta?.description
        ? { name: "description", content: props.pageData.meta.description }
        : null,
      props.pageData?.meta?.robots
        ? { name: "robots", content: props.pageData.meta.robots }
        : null,
    ].filter(Boolean),
    link: props.pageData?.meta?.canonical
      ? [{ rel: "canonical", href: props.pageData.meta.canonical }]
      : [],
    style: getStyles.value
      ? [
          {
            innerHTML: getStyles.value
              .map(({ style, selectors }) => {
                const validSelectors = selectors.filter((s) => {
                  if (typeof s === "string") {
                    return s.startsWith("#");
                  } else if (
                    typeof s === "object" &&
                    "label" in s &&
                    typeof s.label === "string"
                  ) {
                    return s.label.startsWith("#");
                  } else {
                    return false;
                  }
                });
                return validSelectors.length
                  ? `${validSelectors.join(",")} { ${Object.entries(style)
                      .map(([key, value]) => `${key}: ${value}`)
                      .join(";")} }`
                  : "";
              })
              .join("\n"),
          },
        ]
      : [],
  });
};

// Ensure metadata is set immediately when `props.pageData` changes
watch(
  () => props.pageData,
  () => {
    setHeadData();
  },
  { immediate: true },
);

// -----------------------
// Scroll Behavior on Route Change
// -----------------------
watch(structuredContentRef, () => {
  if (route.path === "/") {
    document.body.scrollIntoView({ behavior: "instant" });
    mainStore.headerVisibilityState = "show";
  }
});
</script>

<template>
  <div>
    <!-- fixme: use some hash function or similar here for :key -->
    <template v-if="structuredContentRef">
      <template
        v-for="item in structuredContentRef"
        :key="JSON.stringify(item)"
      >
        <template v-if="item.tagName && item.type !== 'custom-image'">
          <BaseTag
            :tag-name="item.tagName"
            :class="item.classes?.join(' ')"
            :attributes="item.attributes"
          >
            <CmsPageContentBlock
              v-bind="{
                block: item,
              }"
            />
          </BaseTag>
        </template>
        <template v-else>
          <CmsPageContentBlock
            v-bind="{
              block: item,
            }"
          />
        </template>
      </template>
    </template>
  </div>
</template>
